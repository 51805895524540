export const GET_ADMINS = 'user/GET_ADMINS'
export interface GetAdmins {
  type: typeof GET_ADMINS
}

export const SET_ADMINS = 'user/SET_ADMINS'
export interface SetAdmins {
  type: typeof SET_ADMINS
  data: object[]
}

export const EDIT = 'user/EDIT'
export interface Edit {
  type: typeof EDIT
  id: number
  data: object
  callback: () => void
}

export const TOGGLE_AUTHORIZE = 'user/TOGGLE_AUTHORIZE'
export interface ToggleAuthorize {
  type: typeof TOGGLE_AUTHORIZE
  id: number
  value: boolean
  callback: () => void
}

export const TOGGLE_BLOCK = 'user/TOGGLE_BLOCK'
export interface ToggleBlock {
  type: typeof TOGGLE_BLOCK
  id: number
  value: boolean
  callback: () => void
}

export const DELETE_USERS = 'user/DETELE_USERS'
export interface DeleteUsers {
  type: typeof DELETE_USERS
  ids: string
  callback: () => void
}

export const BLOCK_USERS = 'user/BLOCK_USERS'
export interface BlockUsers {
  type: typeof BLOCK_USERS
  ids: string
  callback: () => void
}

export const GET_ALL_USER_TICKETS = 'user/GET_ALL_USER_TICKETS'

export interface GetAllUserTickets {
  type: typeof GET_ALL_USER_TICKETS
  id: number
}

export const SET_ALL_USER_TICKETS = 'user/SET_ALL_USER_TICKETS'
export interface SetAllUserTickets {
  type: typeof SET_ALL_USER_TICKETS
  tickets: any
}

export const GET_ALL_USER_FAILED = 'user/GET_ALL_USER_FAILED'

export interface GetAllUserFailed {
  type: typeof GET_ALL_USER_FAILED
  id: number
}

export const SET_ALL_USER_FAILED = 'user/SET_ALL_USER_FAILED'
export interface SetAllUserFailed {
  type: typeof SET_ALL_USER_FAILED
  failedRequests: any
}

export const GET_SINGLE_SUBSCRIBER = 'user/GET_SINGLE_SUBSCRIBER'

export interface GetSingleSubscriber {
  type: typeof GET_SINGLE_SUBSCRIBER
  id: number,
  callback: any
}

export type UserAction =
  | GetAdmins
  | SetAdmins
  | Edit
  | ToggleAuthorize
  | ToggleBlock
  | DeleteUsers
  | BlockUsers
  | GetAllUserTickets
  | SetAllUserTickets
  | GetAllUserFailed
  | SetAllUserFailed
  | GetSingleSubscriber
