import { SearcherAction } from './actionType'

export type Type = 'phone' | 'name' | 'post' | 'deepweb' 
| 'webint' | 'combined_phone' | 'combined_email' | 'combined_name' | 'combined_id' 
|  'face_search' | 'web_data_search' | 'fb_person_wall_keyword' | 'sentiment_analysys' 
| 'irbis_eye' | 'demo' | 'kyc' | 'web_collection' | 'ip_geo' | 'ipgeo_number'
| 'name_by_country' | 'verify_name' | 'photo_searcher' | 'phone_list' | 'psycho_profile' | 'psycho_profile_summary' 
| 'api_v2_x' | 'api_v2_instagram' | 'api_v2_linkedin'

export type Status = 'progress' | 'finished' | 'failed' | 'empty' | number

export type Phone =
  | 'getcontact'
  | 'drupe'
  | 'skype'
  | 'viber'
  | 'telegram'
  | 'whatsapp'
  | 'eyecon'
  | 'tc'
  | 'callapp'
  | 'hiya'
  | 'imo'
  | 'tamtam'
  | 'ip'
  | 'botim'
  | 'luna'
  | 'taro'
  | 'web_collection_phone'
  | 'web_collection_email'
  | 'eyes'
  | 'mail_reveal'

  export type WebInt = 'quotaprofiler-lookup' | 'quotaprofiler-crawler' | 'quotaprofiler-cases' |'quotaprofiler-periodic' | 'quotaprofiler-monitoring' |
  'quotawebint-lookup' | 'quotawebint-crawler' | 'quotawebint-cases' | 'quotawebint-periodic' | 'quotawebint-monitoring'


export type CombinedPhone = | 'getcontact' | 'drupe' | 'skype' | 'viber' | 'telegram' | 'whatsapp' | 'eyecon' | 'tc' | 'callapp'
  | 'hiya' | 'imo' | 'tamtam' | 'phone' | 'botim' | 'viewcaller' | 'caller_api'

export type CombinedEmail = | 'skype' | 'email' | 'mail_reveal'

export type CombinedNames = | 'fb' | 'instagram' | 'linkedin' | 'twitter' | 'ok' | 'vk' | 'fullname' | 'webmii' 
| 'fb_api' | 'instagram_api' | 'x_api' | 'linkedin_api' | 'vk_api' | 'ok_api' | 'tiktok_api'

export type CombinedId = | 'fb' | 'instagram' | 'linkedin' | 'twitter' | 'ok' | 'vk' 
                        | 'facebookid' | 'telegramid' | 'linkedinid' | 'vkid' | 'twitterid' | 'instagramid' 

export type Social = 'fb' | 'instagram' | 'linkedin' | 'twitter' | 'ok' | 'vk'

export type DeepWeb = 'email' | 'phone' | 'facebookid' | 'telegramid' | 'password' | 'linkedinid' | 'vkid' | 'fullname' | 'pluto'

export type FaceSearch = | 'ok' | 'vk' | 'pluto' | 'tiktok' | 'sniper' | 'sniper_2'

export type APIv2X = | 'people' | 'top' | 'latest' | 'photos' | 'videos' | 'followers' | 'details' | 'feed' | 'tweets' | 'following' | 
'highlights' | 'likes' | 'media' | 'replies' | 'memberships'

export type APIv2Instagram = | 'hashtags' | 'location' | 'users' | 'places' | 'top' | 'account' | 'followers' | 'reels' | 'feed' |
'highlights_tray' | 'reels_media_stream' | 'info' | 'tagsfeed' | 'story' | 'usernameinfo' | 'following'

export type APIv2LinkedIn = | 'locations' | 'groups' | 'jobs' | 'companies' | 'posts' | 'people' | 'honors-and-awards' | 'interests' | 'recommendations' | 'projects' | 'events' | 
'url-resolver' | 'licenses-and-certifications' | 'images' | 'experience' | 'people-also-viewed' | 'education' | 'contact-info' | 'languages' | 'activity' | 'publications' | 'organizations' | 
'overview' | 'skills' | 'test-scores' | 'courses' | 'skill-endorsers' | 'patents' | 'volunteering-experience'

export type Source = Phone | Social | DeepWeb | WebInt | CombinedPhone | CombinedEmail | CombinedNames | CombinedId | FaceSearch | APIv2X | APIv2Instagram | APIv2LinkedIn


interface PhoneData {
  photo?: string | undefined
  avatar?: string
  online?: string
  name?: string
  last_time?: string
  names?: any,
  status?: any,
  displayName?: string,
  emails?: any,
  person?: any,
  possiblePersons?: any,
  sources?: any,
  data?: any,
}

export interface SourcePercentage {
  name: Source
  percentage: number | null
}

interface ResultBase {
  id: number
  requestId: string
  criteria: string
  type: Type
  createdTime: string
  status: Status
  sources: SourcePercentage[]
  percentage?: string
  isDeleted?: boolean
}

export interface PhoneDataItem {
  data: Record<Phone, PhoneData>
  from: Phone
}

interface Education {
  education_level: string
  school_image_url: string
  other_info: string[]
}

export interface LifeEvent {
  [key: number]: string[]
}

interface Work {
  company_name: string
  job_title: string
  other_info: string[]
}

interface LinkedinProfileData {
  'Profile Headline'?: string
}

interface FacebookProfileData {
  education?: Education[]
  life_events?: LifeEvent[]
  work?: Work[]
  places_lived?: {
    current_city: string
    hometown: string
  }
  family_members?: string
  introduction?: string
  relationship?: string
}

export interface NameDataItem {
  from: Social
  profile_id: string
  profileUrl: string
  profileData: LinkedinProfileData & FacebookProfileData
  profileImageUrl: string
  profileName: string
  profileCity: string
  post: string
  createdTime: string
}

export interface NameApiDataItem {
  social_network: string
  profile_id: string
  profileName: string
  profileImageUrl: string
  profile_url : string
  following_count?: number,
  followers_count?: number,
  posts_count: number,
  isPrivate?: boolean,
  isVerified?: boolean,
  created_at?: string,
  location?: string,
  position?: string
  additionalPhoto? : string
}

export interface PostDataItem {
  original_post_user_image: string | undefined
  videos_url: any
  images_url: any
  from: Social
  original_post_user_name: number
  profile_id: string | number
  posted_by_profile_url: string
  text: string
  total_reactions_count: number
  comments_count: number
  post_url: string
  timeStamp: string
  image_url?: string
}


export interface DeepWebDataItem {
  from: Social
  keyword: string,
  customrequestid: number,
  type: string,
  created_date: number,
  address: string,
  country: string,
  date_of_birth: string,
  district: string,
  email: string,
  facebook_id: string,
  facebook_username: string,
  favorite_word: string,
  first_name: string,
  gender: string,
  last_name: string,
  linkedin_id: string,
  linkedin_username: string,
  password: string,
  phone: string,
  profession: string,
  source_name: string,
  ssn: string,
  telegram_id: string,
  telegram_nickname: string,
  telegram_username: string,
  vk_id: string,
}

export interface CombinedPhoneItem {
  from: Social
  keyword: string,
  customrequestid: number,
  type: string,
  created_date: number,
  address: string,
  country: string,
  date_of_birth: string,
  district: string,
  email: string,
  facebook_id: string,
  facebook_username: string,
  favorite_word: string,
  first_name: string,
  gender: string,
  last_name: string,
  linkedin_id: string,
  linkedin_username: string,
  password: string,
  phone: string,
  profession: string,
  source_name: string,
  ssn: string,
  telegram_id: string,
  telegram_nickname: string,
  telegram_username: string,
  vk_id: string,
}

export interface CombinedNameItem {
  from: Social
  profile_id: string
  profileUrl: string
  profileData: LinkedinProfileData & FacebookProfileData
  profileImageUrl: string
  profileName: string
  profileCity: string
  post: string
  createdTime: string
  keyword: string,
  customrequestid: number,
  type: string,
  created_date: number,
  address: string,
  country: string,
  date_of_birth: string,
  district: string,
  email: string,
  facebook_id: string,
  facebook_username: string,
  favorite_word: string,
  first_name: string,
  gender: string,
  last_name: string,
  linkedin_id: string,
  linkedin_username: string,
  password: string,
  phone: string,
  profession: string,
  source_name: string,
  ssn: string,
  telegram_id: string,
  telegram_nickname: string,
  telegram_username: string,
  vk_id: string,
}

export interface CombinedIdItem {
  from: Social
  profile_id: string
  profileUrl: string
  profileData: LinkedinProfileData & FacebookProfileData
  profileImageUrl: string
  profileName: string
  profileCity: string
  post: string
  createdTime: string
  keyword: string,
  customrequestid: number,
  type: string,
  created_date: number,
  address: string,
  country: string,
  date_of_birth: string,
  district: string,
  email: string,
  facebook_id: string,
  facebook_username: string,
  favorite_word: string,
  first_name: string,
  gender: string,
  last_name: string,
  linkedin_id: string,
  linkedin_username: string,
  password: string,
  phone: string,
  profession: string,
  source_name: string,
  ssn: string,
  telegram_id: string,
  telegram_nickname: string,
  telegram_username: string,
  vk_id: string,
}

export interface FaceSearchIdItem {
  original_post_user_image: string | undefined,
  age: number,
  bio: string,
  born: string,

  city: string,
  country: string,

  face: string,
  face_db: string,
  filtered: number,
  first_name: string,
  last_name: string,
  maiden_name: string,
  photo: string,
  photo_height: number,
  photo_id: string,
  photo_width: number,
  photo_x: number,
  photo_y: number,
  profile: string,
  score: string,
  source: string,
  user_id: string,
  network?: string,
  network_icon?: string,
  details?: any[],
}

export interface SentimentAnalysysItem {
  text : string,
  sentiment: string,
  topic: string,
  summary: string
  entity: string,
  status: string,
  createdTime: string,
}

export interface KYCItem {
  country: string
  domain: string
  employees_count: number
  industry: string
  linkedin_url: string
  locality : string
  name: string
  year_founded: number
  iban: string
  is_valid: boolean
  vat_number: string,
  vat_valid: boolean,
  company_name: string,
  comapany_address: string,
  company_country_code: string,
  company_country_name: string,
  source_type: string
}

// export interface IPGeolocationNumberItem {

// }

export interface IPGeolocationItem {
  ip_address: string
  city: string
  city_geoname_id: number
  region: string
  region_geoname_id: number
  region_iso_code: string
  postal_code: number
  country: string
  country_code: string
  country_geoname_id: number
  country_is_eu: boolean
  continent: string
  continent_code: string
  continent_geoname_id: number
  latitude: number
  longitude: number
  is_vpn: boolean
}

export interface PsychoAnalysysItem {
  image? : string,
  levelOfDanger? : string,
  personName? : string,
  predictedCharacteristics? : string[],
  psychologicalPortrait? : string,
  age? : string,
  country? : string,
  gender?: string,
  occupation? : string,
  summary? : string
}

export interface IpPhoneGeolocationItem {
  phone?: string,
  phoneCountry?: string,
  phoneCountryCode?: string,
  ip?: string,
  ipCountry?: string,
  ipCountryCode?: string,
  status?: boolean
}

export interface WebCollectionItem {
  image : string
  htmlCode : string
  source_type: string
}

export interface PhoneLookupResult extends ResultBase {
  from?: string
  data?: PhoneDataItem[]
}

export interface NameLookupResult extends ResultBase {
  data?: NameDataItem[]
}

export interface NameApiLookupResult extends ResultBase {
  data?: NameApiDataItem[]
}

export interface PostLookupResult extends ResultBase {
  data?: PostDataItem[]
}

export interface KYCResult extends ResultBase {
  data?: KYCItem[]
}

export interface IPGeolocationResult extends ResultBase {
  data?: IPGeolocationItem[]
}

export interface IpPhoneGeolocationResult extends ResultBase {
  data?: IpPhoneGeolocationItem[]
}

export interface IPsychoAnalysysResult extends ResultBase {
  data?: PsychoAnalysysItem[]
}

export interface WebCollectionResult extends ResultBase {
  data?: WebCollectionItem[]
}

export interface DeepWebLookupResult extends ResultBase {
  data?: DeepWebDataItem[]
}

export interface CombinedPhoneResult extends ResultBase {
  data?: CombinedPhoneItem[]
}

export interface CombinedNameResult extends ResultBase {
  data?: CombinedNameItem[]
}

export interface CombinedIdResult extends ResultBase {
  data?: CombinedIdItem[]
}

export interface FaceSearchResult extends ResultBase {
  data?: FaceSearchIdItem[]
}

export interface SentimentAnalysysResult extends ResultBase {
  data?: SentimentAnalysysItem[]
}

export type SearchResult =
| PhoneLookupResult
| NameLookupResult
| PostLookupResult
| DeepWebLookupResult
| CombinedPhoneResult
| CombinedNameResult
| CombinedIdResult
| FaceSearchResult
| SentimentAnalysysResult
| KYCResult
| IPGeolocationResult
| WebCollectionResult
//| IPGeolocationNumberResult


export interface ILookup {
  id: number
  type: Type
  title: string
  price: number
  sources: Source[]
  subTitle: string
  input: string
  button: string
  color: string
}

export type SearcherState = {
  pdfData: any
}

const initialState: SearcherState = {
  pdfData: null,
}

export function searcherReducer(state = initialState, action: SearcherAction) {
  switch (action.type) {
    default:
      return state
  }
}
