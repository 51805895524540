import {PackageAction} from './actionType'

export type PackageType = 'general' | 'personal' | 'custom' | 'webinthub' | 'webinthub_subpackage' | 'telegrambt' | 'webinthub_upgrade' | 'demo' | 'webinthub_demo'
| 'one_time_fee'

export type WorkflowType = 'phone' | 'name' | 'name_api' | 'post' | 'deepweb' | 'webint' | 'combined_phone' | 'combined_email' | 'combined_name' | 'combined_id' | 'face_search' | 'additional_quotas' |
  'web_data_search' | 'fb_person_wall_keyword' | 'sentiment_analysys' | 'irbis_eye' | 'kyc' | 'web_collection' | 'ip_geo' | 'name_by_country' | 'photo_searcher'  | 'phone_list'
  | 'ipgeo_number' | 'psycho_profile' | 'psycho_profile_summary' | 'api_v2_x' | 'api_v2_instagram' | 'api_v2_linkedin' 
  | 'sn_api_profiler' | 'sn_api_group_profiler' | 'lookup_phone_verification' | 'lookup_phone_name_verification' | 'real_phone' 
  | 'sn_api_profiler_fb_friends' | 'sn_api_profiler_fb_posts' | 'sn_api_profiler_insta_friends' 
  | 'sn_api_profiler_insta_posts' | 'irbis_facebook' | 'crolo_x' | 'crolo_vk' | 'crolo_ok' 
  | 'irbis_instagram' | 'irbis_tiktok' | 'irbis_criminal'

export type SubtitleType = 'text' | 'status'

export type Phone = | 'getcontact' | 'drupe' | 'skype' | 'viber' | 'telegram' | 'whatsapp' | 'eyecon' | 'tc' | 'callapp'
  | 'hiya' | 'imo' | 'tamtam' | 'botim'

export type Social = 'fb' | 'instagram' | 'linkedin' | 'twitter' | 'ok' | 'vk'

export type NameApi = 'fb' | 'instagram' | 'linkedin' | 'x' | 'vk' | 'ok' | 'tiktok'

export type DeepWeb = 'email' | 'phone' | 'facebookid' | 'telegramid' | 'password' | 'linkedinid' | 'vkid' | 'fullname' | 'twitterid' |'instagramid' 

export type WebInt = 'quotaprofiler-lookup' | 'quotaprofiler-crawler' | 'quotaprofiler-cases' |'quotaprofiler-periodic' | 'quotaprofiler-monitoring' |
                     'quotawebint-lookup' | 'quotawebint-crawler' | 'quotawebint-cases' | 'quotawebint-periodic' | 'quotawebint-monitoring'

export type WebintQoutasAdditional = ''

export type CombinedPhone = | 'getcontact' | 'drupe' | 'skype' | 'viber' | 'telegram' | 'whatsapp' | 'eyecon' | 'tc' | 'callapp'
| 'hiya' | 'imo' | 'tamtam' | 'phone' | 'botim' | 'luna' | 'taro' | 'web_collection_phone' | 'viewcaller' | 'caller_api'

export type CombinedEmail = | 'skype' | 'email' | 'luna' | 'taro' | 'web_collection_email' | 'eyes' | 'mail_reveal'

export type CombinedNames = | 'fb' | 'instagram' | 'linkedin' | 'twitter' | 'ok' | 'vk' | 'fullname' | 'webmii' 
| 'fb_api' | 'instagram_api' | 'x_api' | 'linkedin_api' | 'vk_api' | 'ok_api' | 'tiktok_api'

export type CombinedId = | 'fb' | 'instagram' | 'linkedin' | 'twitter' | 'ok' | 'vk' 
                        | 'facebookid' | 'telegramid' | 'linkedinid' | 'vkid' | 'twitterid' | 'instagramid' | 'instanavigation_person' 

export type FaceSearch = | 'ok' | 'vk' | 'pluto' | 'tiktok' | 'sniper' | 'sniper_2'

export type AdvancedCollectionFacebook = 
| 'search_users' | 'search_posts' 
| 'person_id_resolving' | 'person_intro' | 'person_about' | 'person_groups' | 'person_friends' | 'person_wall' | 'person_post_and_feedbacks' | 'person_reactions' | 'person_feedbacks' | 'person_comment_reactions' | 'person_photo_albums' 
| 'person_content_data' | 'person_content' | 'person_video_albums' | 'person_specific_comment' | 'person_specific_media' 
| 'group_id_resolving' | 'group_wall' | 'group_post_and_feedbacks' | 'group_feedbacks' | 'group_reactions' | 'group_photo_albums' | 'group_photos' | 'group_members' | 'group_admins' | 'group_intro' 
| 'group_about' | 'group_content_data' | 'group_comment_reactions' | 'group_specific_comment' | 'group_specific_media' 
| 'page_id_resolving' | 'page_wall' | 'page_community_wall' | 'page_post_and_feedbacks' | 'page_reactions' | 'page_feedbacks' | 'page_photo_albums' | 'page_content_data' | 'page_content' | 'page_intro' | 'page_about' | 'page_transparency' 
| 'page_linked_profile' | 'page_video_albums' | 'page_comment_reactions' | 'page_specific_comment' | 'page_specific_media'

export type FacebookMobileCollector = 
| 'general_id_resolving' | 'general_reactions' | 'general_feedbacks' | 'general_comment_reactions' | 'general_shares' | 'general_specific_comment' | 'general_specific_media'
| 'group_intro' | 'group_about' | 'group_admins' | 'group_members' | 'group_photo_albums' | 'group_photos' | 'group_wall'
| 'page_intro' | 'page_linked_profile' | 'page_about' | 'page_photo_albums' | 'page_video_albums' | 'page_wall' | 'page_community_wall' | 'page_transparency'
| 'post_post_and_feedbacks'
| 'user_intro' | 'user_about' | 'user_friends' | 'user_video_albums' | 'user_photo_albums' | 'user_wall' | 'user_groups'
| 'album_metadata' | 'album_content' | 'album_content_data'
| 'keyword_posts' | 'keyword_users'

export type InstagramMobileCollector = 
| 'url_resolving' | 'media_likers' | 'userid_by_username_url' | 'user_info_by_username_url' | 'about'
| 'highlights' | 'full_highlights' | 'feed' | 'tagged' | 'following' | 'followers' | 'story' | 'data' | 'likers' 
| 'comments' | 'comment_likers' | 'replies' | 'tags' | 'tags_sections'

export type AdvCollectionFbPersonWall = 'person_wall'

export type SentimentAnalysys = | 'sentiment_analysys'

export type FindClone = | 'irbis_eye'

export type KYC = | 'kyc_iban' | 'kyc_company_enrich' | 'kyc_vat_validate'

export type WEB_Collection = | 'web_collection_screenshot' | 'web_collection_scrape'

export type IPGeo = | 'geolocation'

export type IPGeoNumber = | 'geolocation_number' | 'email_domain_validator'

export type NameByCountry = | 'country_fullname'

export type SniperAPI = | 'sniper_api'

export type PhoneList = | 'getcontact' | 'drupe' | 'skype' | 'viber' | 'telegram' | 'whatsapp' | 'eyecon' | 'tc' | 'callapp'
| 'hiya' | 'imo' | 'tamtam' | 'phone' | 'botim' | 'luna' | 'taro' | 'web_collection_phone'

export type PsychoProfile = | 'summary' | 'psych'

export type APIv2X = | 'people' | 'top' | 'latest' | 'photos' | 'videos' | 'followers' | 'details' | 'feed' | 'tweets' | 'following' | 
'highlights' | 'likes' | 'media' | 'replies' | 'memberships'

export type APIv2Instagram = | 'hashtags' | 'location' | 'users' | 'places' | 'top' | 'account' | 'followers' | 'reels' | 'feed' |
'highlights_tray' | 'reels_media_stream' | 'info' | 'tagsfeed' | 'story' | 'usernameinfo' | 'following'

export type APIv2LinkedIn = | 'locations' | 'groups' | 'jobs' | 'companies' | 'posts' | 'people' | 'honors-and-awards' | 'interests' | 'recommendations' | 'projects' | 'events' | 
'url-resolver' | 'licenses-and-certifications' | 'images' | 'experience' | 'people-also-viewed' | 'education' | 'contact-info' | 'languages' | 'activity' | 'publications' | 'organizations' | 
'overview' | 'skills' | 'test-scores' | 'courses' | 'skill-endorsers' | 'patents' | 'volunteering-experience'

export type SNApiProfile = | 'fbProfile' | 'fbGroup' | 'fbPage' | 'instaProfile'

export type SNApiWebintGroupProfile = | 'fbGroupProfile'

export type LookupPhoneVerification = | 'VERIFICATION'

export type CroloX = 'verify_credentials' | 'get_user_by_id' | 'search_users_x' | 'people_by_query' | 'people_by_geo' | 'list_by_query' | 'list_by_geo' |
'latest_tweets_by_query' | 'latest_tweets_by_geo' | 'top_tweets_by_query' | 'top_tweets_by_geo' | 'tweet_highlights_for_user' | 'tweets_for_user' | 'tweet_media_for_user' |
'tweet_media_by_query' | 'tweet_with_discussion' | 'tweet_media_by_geo' | 'subscriptions_for_user' | 'superfollows_for_user' | 'retweets_for_tweet' | 'followers_for_user'

export type CroloVk = 'friends_get_id' | 'friends_get_name' | 'friends_get_user_query' | 'groups_getbyid_group_query' | 'groups_getbyid_groupid' | 'groups_getbyid_name' | 
'groups_getbyid_post_query' | 'groups_getmembers_group_query' | 'groups_getmembers_groupid' | 'groups_search_group_query' | 'likes_getlist_groupid' | 'newsfeed_search_post_query' | 
'photos_get_group_query' | 'photos_get_groupid' | 'photos_get_id' | 'photos_get_name' | 'photos_get_user_query' | 'users_get_groupid' | 
'users_get_id' | 'users_get_name' | 'users_get_post_query' | 'users_get_user_query' | 'users_phone' | 'users_search_id' | 'users_search_name' | 'users_search_user_query' | 'wall_get_groupid' | 
'wall_getcomments_groupid' | 'wall_getcomments_post_query'

export type CroloOk = 'badges' | 'communities' | 'discussions' | 'friends' | 'group' | 'hobbies' | 'interests' |
'market' | 'media_topic' | 'moments' | 'photos' | 'places' | 'search' | 'stream' | 'url' | 'users' | 'entity'

export type IrbisTikTok = 'user_info' | 'user_following' | 'user_followers' | 'user_videos' | 'video_info'

export type IrbisCriminal = 'ssn_trace' | 'nat_crim_check' | 'pep_nm_wls'

export type Source = Phone | Social | NameApi | DeepWeb | WebInt | CombinedPhone | CombinedEmail 
            | CombinedNames | CombinedId | FaceSearch | AdvancedCollectionFacebook | SentimentAnalysys | FindClone
            | KYC | WEB_Collection | IPGeo | NameByCountry | SniperAPI | AdvCollectionFbPersonWall | IPGeoNumber 
            | PsychoProfile | APIv2X | APIv2Instagram | APIv2LinkedIn | SNApiProfile | SNApiWebintGroupProfile | LookupPhoneVerification 
            | FacebookMobileCollector | InstagramMobileCollector | CroloX | CroloVk | CroloOk | IrbisTikTok | IrbisCriminal

export type Duration = 1 | 5 | 15 | 30 | 90 | 180 | 365

export interface Workflow {
  id: number
  type: WorkflowType
  title: string
  subTitle: string
  price: number
  fakeCheckPrice : number | null
  maxNum : number | null
  input: string
  button: string
  color: string
  sources: Source[]
}

export interface IFeature {
  title: string,
  subTitle: string,
  info: string
}

export interface Package {
  id: number
  productId: number
  uid: string | null
  type: PackageType
  title: string
  subTitle: { context: string; type: SubtitleType } | null
  bonus: number | null
  price: number | null
  creditsPerUnit: number | null
  lookups: Array<{ id: number, title: string }>
  feature1: IFeature | null
  feature2: IFeature | null
  feature3: IFeature | null
  feature4: IFeature | null
  sources: Source[]
  button: string
  demoLink: string
  activeDay: Duration
  isFree: boolean
  isAnnual : boolean
  isActive : boolean
  apiRequestsLimits : number | null
  apiRequestsInterval : number | null
  annualProductId : number | null
  annualPrice: number | null
}

export interface PackageRule {
  id: number
  title: string
  isFree: boolean
  isAnnual : boolean
}

export type PackageState = {
  packages: Package[] | null
  packageRules: PackageRule[] | null
}

const initialState: PackageState = {
  packages: null,
  packageRules: null,
}

export function packageReducer(state = initialState, action: PackageAction) {
  switch (action.type) {
    case 'package/SET_PACKAGE':
      return {
        ...state,
        packages: action.data,
      }
    case 'package/SET_PACKAGE_RULES':
      return {
        ...state,
        packageRules: action.data,
      }
    default:
      return state
  }
}
