import {t} from 'i18next'
import {TicketPriority} from '../store/support/reducer'

export function getPercentSize(value: number, topValue: number) {
  if (value === 0 || topValue === 0) return '0%'
  return value / topValue * 100 + '%'
}

export function getPriorityName(value: TicketPriority) {
  switch (value) {
    case 'low': return t('common.low')
    case 'medium': return  t('common.medium')
    case 'high': return  t('common.high')
  }
}

export function getSourceName(value: string) {
  switch (value) {
    case 'ok': return 'OK'
    case 'vk': return 'VK'
    case 'fb': return 'Facebook'
    case 'tc': return 'True'
    case 'callapp': return 'CallApp'
    case 'telegramid': return 'telegram'
    case 'facebookid': return 'facebook'
    case 'linkedinid': return 'linkedIn'
    case 'vkid': return 'vk'
    case 'email': return 'e-mail'
    case 'fullname' : return 'full name'
    case 'twitterid' : return 'twitter'
    case 'instagramid' : return 'instagram'
    case 'instanavigation_person' : return 'Instaperson'
    case 'irbis_eye' : return 'Irbis Eye'
    case 'web_collection_phone' : return 'Webcoll Phone'
    case 'web_collection_email' : return 'Webcoll E-mail'
    case 'kyc_iban' : return 'IBAN'
    case 'kyc_company_enrich' : return 'Comp. Enrich'
    case 'kyc_vat_validate' : return 'VAT Validation'
    case 'web_collection_screenshot' : return 'Screenshot'
    case 'web_collection_scrape' : return 'Web Scrape'
    case 'geolocation' : return 'IP Geolocation'
    case 'geolocation_number' : return 'Geolocation by Phone Number'
    case 'email_domain_validator' : return 'E-mail domain validator'
    case 'country_fullname' : return 'Full Name by country'
    case 'sniper_api': return 'Sniper API'
    case 'VERIFICATION': return 'AI verification'
    case 'fb_api' : return 'Facebook'
    case 'instagram_api' : return 'Instagram'
    case 'vk_api' : return 'Vk'
    case 'ok_api' : return 'Ok'
    case 'x_api' : return 'X'
    case 'linkedin_api' : return 'LinkedIn'
    case 'tiktok_api' : return 'TikTok'
    case 'mail_reveal' : return 'E-mail Reveal'
    case 'irbis_tiktok' : return 'TikTok'
    case 'irbis_criminal': return 'Criminal Checks'
    case 'ssn_trace' : return 'SSN Trace'
    case 'nat_crim_check': return 'National Criminal Screening'
    case 'pep_nm_wls': return 'Watchlists, Politically Exposed Persons, Adverse Media Screening'
    default: return value
  }
}

export function getWebintName(value : string) {
  switch (value) {
  case 'quotaprofiler-lookup' : return 'Profiler Lookup'
  case 'quotaprofiler-crawler' : return 'Profiler Crawler'
  case 'quotaprofiler-cases' : return 'Profiler Cases'
  case 'quotaprofiler-periodic' : return 'Profiler Periodic'
  case 'quotaprofiler-monitoring' : return 'Profiler Monitoring'
  case 'quotawebint-lookup' : return 'WebInt Lookup' 
  case 'quotawebint-crawler': return 'WebInt Crawler'
  case 'quotawebint-cases': return 'WebInt Cases'
  case 'quotawebint-periodic': return 'WebInt Periodic'
  case 'quotawebint-monitoring': return 'WebInt Monitoring'
  }
}

export function genRandomHex(size: number) {
  return '#' + [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')
}
