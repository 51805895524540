import { call, put, takeEvery } from 'redux-saga/effects'
import * as actionType from './actionType'
import { showSnackbar } from '../common/actionCreator'
import { api, Response } from '../../function/axios'
import { handleError } from '../../function/error'
import { setAdmins, setAllUserFailed, setAllUserTickets } from './actionCreator'

function* getAdmins() {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: '/users/users?limit=1000',
    })

    yield put(setAdmins(data.list))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* edit(action: actionType.Edit) {
  try {
    const { data }: Response = yield call(api, {
      method: 'PUT',
      url: `/users/edit/${action.id}`,
      body: action.data,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* toggleAuthorize(action: actionType.ToggleAuthorize) {
  try {
    const { data }: Response = yield call(api, {
      method: 'PUT',
      url: '/auth/admin/role',
      body: {
        isAdmin: action.value,
        userId: action.id,
      },
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* toggleBlock(action: actionType.ToggleBlock) {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: `/users/block/${action.id}?isBlocked=${action.value}`,
    })

    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* blockUsers(action: actionType.BlockUsers) {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: `/users/block_selected`,
      params: {
        ids: action.ids,
      },
    })
    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* deleteUsers(action: actionType.DeleteUsers) {
  try {
    const { data }: Response = yield call(api, {
      method: 'DELETE',
      url: '/users',
      params: {
        ids: action.ids,
      },
    })
    yield call(action.callback)
    yield put(showSnackbar('success', data.message))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getAllUserTickets(action: actionType.GetAllUserTickets) {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: `/pdf/tickets?id=${action.id}`,
    })

    yield put(setAllUserTickets(data))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getAllUserFailed(action: actionType.GetAllUserFailed) {
  try {
    const { data }: Response = yield call(api, {
      method: 'GET',
      url: `/pdf/failed-requests?id=${action.id}`,
    })

    yield put(setAllUserFailed(data))
  } catch (e) {
    yield call(handleError, e)
  }
}

function* getSingleSubscriber(action: actionType.GetSingleSubscriber) {
  try {
    
    const {id, callback} = action
    let {data}: Response = yield call(api, {
      method: 'GET',
      url: `/admin-dashboard/usage/${id}`,
    })

    const counters = data
    const response = <Response> (yield call(api, {
      method: 'GET',
      url: `/users/client/${id}`,
    }))

    data = response.data
    data.counters = counters
    console.log("from getSingleSubscriber", data)

    yield call(callback, data)
  } catch (e) {
    yield call(handleError, e)
  }
}

export function* watchUser() {
  yield takeEvery(actionType.GET_ADMINS, getAdmins)
  yield takeEvery(actionType.EDIT, edit)
  yield takeEvery(actionType.TOGGLE_AUTHORIZE, toggleAuthorize)
  yield takeEvery(actionType.TOGGLE_BLOCK, toggleBlock)
  yield takeEvery(actionType.DELETE_USERS, deleteUsers)
  yield takeEvery(actionType.BLOCK_USERS, blockUsers)
  yield takeEvery(actionType.GET_ALL_USER_TICKETS, getAllUserTickets)
  yield takeEvery(actionType.GET_ALL_USER_FAILED, getAllUserFailed)
  yield takeEvery(actionType.GET_SINGLE_SUBSCRIBER, getSingleSubscriber)
}
